<template>
  <TaskDetailItemEditCard confirmation-message="Werkschema werd succesvol gewijzigd" title="Bewerk werkschema"
    update-store-action="tasksModule/updateTimeSlots"
    :update-store-action-payload="{ projectId: projectId, clientId: clientId, taskId: taskId, payload: { timeSlots: timeSlots } }"
    :projectId="projectId" :clientId="clientId" :taskId="taskId" :promises.sync="promises">
    <template v-slot:form-fields>
      <DayPlanningComponent v-model="timeSlots" editable />
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import DayPlanningComponent from "@/components/planning/DayPlanningComponent";


export default {
  name: "TaskDetailEditWorkSchema",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      promises: [],
      timeSlots: {
        MONDAY: null,
        TUESDAY: null,
        WEDNESDAY: null,
        THURSDAY: null,
        FRIDAY: null,
        SATURDAY: null,
        SUNDAY: null
      }
    }
  },
  components: {
    TaskDetailItemEditCard,
    DayPlanningComponent
  },
  methods: {
    getRequiredRules,
    getStartsWithRules,
    getMaxStringSizeRules
  },
  created() {
    this.promises.push(this.$store.dispatch('tasksModule/fetchTimeSlots', { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
      .then((result) => {
        if (result) {
          this.timeSlots = result
        }
      })
    )
  }
}
</script>